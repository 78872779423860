import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { type TimelineMode } from "~/utils/constants";

type Props = {
  timelineMode: TimelineMode;
  onButtonClick: () => void;
};

export const TimelineButton = (props: Props) => {
  return (
    <div
      className="flex h-fit w-fit cursor-pointer items-center gap-[4px]"
      onClick={props.onButtonClick}
    >
      <span className="select-none text-[15px] font-medium leading-[18px] text-[rgba(0,0,0,0.85)]">
        {props.timelineMode}
      </span>
      <SVGAtom iconName="downArrow" width={10} height={10} color="#000" />
    </div>
  );
};
