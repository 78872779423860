type Props = {
  totalVotes: number;
};

export const TotalVotes = (props: Props) => {
  return (
    <div className="flex items-center gap-[4px]">
      <p className="text-[15px] leading-[20px]">Total</p>
      <p
        className="text-[18px] font-bold leading-[20px]"
        style={{
          background: "linear-gradient(90deg, #2F54EB 0%, #9747FF 90%)",
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {props.totalVotes.toLocaleString()}
      </p>
    </div>
  );
};
