import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { BottomSheet } from "../BottomSheet/BottomSheet";

type contentProps = {
  iconProps?: React.ComponentProps<typeof SVGAtom>;
  text: string;
  color?: string;
  onClick: () => void;
};

type Props = {
  bottomSheetContent: contentProps[];
  closeBottomSheet: React.ComponentProps<
    typeof BottomSheet
  >["closeBottomSheet"];
};

export const BottomSheetItem = (props: Props) => {
  return (
    <>
      {props.bottomSheetContent.map((content, index) => (
        <div
          className="flex w-full cursor-pointer items-center gap-[8px] px-[30px] py-[18px]"
          key={index}
          onClick={() => {
            props.closeBottomSheet();
            content.onClick();
          }}
        >
          <div className="flex items-center gap-[8px]">
            {content.iconProps && (
              <SVGAtom
                width={12}
                height={12}
                color={content.color ?? "#000"}
                {...content.iconProps}
              />
            )}
            <p
              className="text-[15px] leading-[20px]"
              style={{ color: content.color ?? "#000" }}
            >
              {content.text}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};
