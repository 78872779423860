import { type File, FileType } from "@prisma/client";
import { Col, Row } from "antd";
import { AudioOutlined, PlayCircleOutlined } from "@ant-design/icons";
import Image from "next/image";

type Props = {
  files: File[];
};

export const FeedFileThumbnail = (props: Props) => {
  const thumbnails = props.files?.filter((item) =>
    [FileType.IMAGE, FileType.VIDEO].includes(item.fileType as any),
  );

  const hasAudio = !!props.files?.filter((item) =>
    [FileType.AUDIO].includes(item.fileType as any),
  )?.length;

  const moreCount = thumbnails?.length - 3;

  return !thumbnails?.length ? (
    <></>
  ) : (
    <Row className="aspect-16/10 w-full">
      {thumbnails?.length === 1 ? (
        <Col span={24} style={{ height: "100%" }}>
          <Thumbnail file={thumbnails[0]} hasAudio={hasAudio} />
        </Col>
      ) : thumbnails.length === 2 ? (
        <>
          <Col span={12} style={{ height: "100%" }}>
            <Thumbnail file={thumbnails[0]} hasAudio={hasAudio} />
          </Col>

          <Col span={12} style={{ height: "100%" }}>
            <Thumbnail file={thumbnails[1]} />
          </Col>
        </>
      ) : (
        <>
          <Col span={16} style={{ height: "100%" }}>
            <Thumbnail file={thumbnails[0]} hasAudio={hasAudio} />
          </Col>

          <Col span={8} style={{ height: "100%" }}>
            <Row className="h-[50%]">
              <Thumbnail file={thumbnails[1]} />
            </Row>
            <Row className="h-[50%]">
              <Thumbnail file={thumbnails[2]} moreCount={moreCount} />
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};

const Thumbnail = ({
  file,
  hasAudio = false,
  moreCount = 0,
}: {
  file: any;
  hasAudio?: boolean;
  moreCount?: number;
}) => {
  return (
    <div className="relative !h-full w-full ">
      {file?.fileType === FileType.IMAGE ? (
        <>
          <Image
            src={file.url}
            fill
            objectFit="cover"
            style={{ opacity: moreCount ? 0.5 : 1 }}
            alt={`image thumbnail`}
          />
          {hasAudio && (
            <div className="absolute inset-3 flex items-end justify-end">
              <AudioOutlined
                style={{ fontSize: "20px", color: "rgba(0, 0, 0, 0.6)" }}
              />
            </div>
          )}
          {moreCount > 0 && (
            <div className="absolute inset-0 flex items-center justify-center text-white">
              + {moreCount}
            </div>
          )}
        </>
      ) : (
        <>
          {file?.thumbnailUrl ? (
            <Image
              src={file.thumbnailUrl}
              fill
              objectFit="cover"
              style={{ opacity: moreCount ? 0.5 : 1 }}
              alt={`video thumbnail`}
            />
          ) : (
            <div className=" flex h-full w-full justify-center">
              {/* <VideoCameraOutlined className="text-current text-[50px]" /> */}
            </div>
          )}
          <div className="absolute inset-0 flex items-center justify-center">
            <PlayCircleOutlined
              style={{ fontSize: "48px", color: "rgba(0, 0, 0, 0.6)" }}
            />
          </div>
          {hasAudio && (
            <div className="absolute inset-3 flex items-end justify-end">
              <div className="flex items-center justify-center rounded-full bg-white p-2 shadow">
                <AudioOutlined
                  style={{ fontSize: "20px", color: "rgba(0, 0, 0, 0.6)" }}
                />
              </div>
            </div>
          )}
          {moreCount > 0 && (
            <div className="absolute inset-0 flex items-center justify-center text-white">
              + {moreCount}
            </div>
          )}
        </>
      )}
    </div>
  );
};
