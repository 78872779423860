import { Modal, Button, message } from "antd";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";
import { api } from "~/trpc/react";
import { copyToClipboard } from "~/utils/copyToClipBoard";
import { shortenUrl } from "~/utils/shortenUrl";
import Image from "next/image";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { useDisableScroll } from "~/hooks/useDisableScroll";

type Props = {
  shareUrl: string;
  isModalVisible: boolean;
  feedId?: number;
  voteId?: number;
  fandomId?: number;
  setIsModalVisible: (value: boolean) => void;
};

export const ShareModal = (props: Props) => {
  const { data: session, update } = useSession();
  const { disableScroll, enableScroll } = useDisableScroll();

  const apiGiveFirstSharePoint = api.point.giveFirstSharePoint.useMutation({
    onSuccess: (data) => {
      if (data) {
        void message.success(
          `You received 50 FAO for sharing ${
            props.feedId
              ? "Feed"
              : props.voteId
                ? "Vote"
                : props.fandomId
                  ? "Fandom"
                  : ""
          }`,
        );
      }
    },
  });

  // const showModal = () => {
  //   props.setIsModalVisible(true);
  // };

  const handleCancel = () => {
    props.setIsModalVisible(false);
  };

  useEffect(() => {
    if (props.isModalVisible) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [props.isModalVisible]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const { Kakao } = window;

      if (!Kakao?.isInitialized()) {
        Kakao.init(process.env.NEXT_PUBLIC_KAKAO_SHARE_KEY);
      }
    }
  }, []);

  return (
    <Modal
      centered
      width={315}
      getContainer={false}
      open={props.isModalVisible}
      style={{ width: "100%" }}
      footer={null}
      closeIcon={
        <SVGAtom iconName="close" width={20} height={20} color="#000" />
      }
      onCancel={handleCancel}
    >
      <div className="flex w-full flex-col items-center justify-center gap-[8px]">
        <p className="text-[17px] font-semibold leading-[26px]">Share</p>
        <div className="flex flex-1 flex-shrink-0 flex-col items-center gap-[16px]">
          <div
            className="flex items-start justify-between gap-[16px] self-stretch"
            onClick={() =>
              apiGiveFirstSharePoint.mutate({
                userId: session?.user?.id,
                feedId: props.feedId,
                voteId: props.voteId,
                fandomId: props.fandomId,
              })
            }
          >
            <TwitterShareButton
              className="flex flex-col items-center justify-center gap-[5px]"
              url={props.shareUrl}
            >
              <XIcon size={50} round />X
            </TwitterShareButton>
            <FacebookShareButton
              className="flex flex-col items-center justify-center gap-[5px]"
              url={props.shareUrl}
            >
              <FacebookIcon size={50} round />
              Facebook
            </FacebookShareButton>
            <TelegramShareButton
              className="flex flex-col items-center justify-center gap-[5px]"
              url={props.shareUrl}
            >
              <TelegramIcon size={50} round />
              Telegram
            </TelegramShareButton>
            {/* <KakaoButton shareUrl={props.shareUrl} /> */}
          </div>
          <div className="flex h-[50px] items-center justify-between self-stretch rounded-[8px] border-[1px] border-[#E0E0E0] bg-[#f9f9f9] p-[12px]">
            <p>{shortenUrl(props.shareUrl)}</p>
            <Button
              type="primary"
              onClick={() => copyToClipboard(props.shareUrl)}
            >
              Copy
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const KakaoButton = ({ shareUrl }: { shareUrl: string }) => {
  // const setShowLoaderOverlay = useSetRecoilState(showLoaderOverlayAtom);

  const handleShare = () => {
    // void setShowLoaderOverlay(true);
    const { Kakao } = window;

    Kakao.Share.sendScrap({
      requestUrl: shareUrl,
    });
  };

  return (
    <div
      className="flex flex-col items-center justify-center gap-[5px]"
      onClick={handleShare}
    >
      <div className="relative h-[50px] w-[50px]">
        <Image
          className="h-10 w-10 cursor-pointer"
          src={
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2Yu_hCkPP5C7Tx8_8a9RvFXW-PvGnuEzjuQ&usqp=CAU"
          }
          fill
          objectFit="contain"
          alt="카카오톡 공유 이미지"
        />
      </div>
      <p className="text-center text-[13px] leading-[22px]">Kakao</p>
    </div>
  );
};
