"use client";

import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { TimelineMode } from "~/utils/constants";
import { useQueryState } from "nuqs";
import { useRecoilState } from "recoil";
import { lastDatasAtom } from "~/state/atoms";

export const useTimelineMode = () => {
  const searchParam = useSearchParams();
  const tempTimelineMode =
    searchParam?.get("timelineMode") === TimelineMode.HOT
      ? TimelineMode.HOT
      : TimelineMode.NEW;
  const [timelineMode, setTimelineMode] =
    useState<TimelineMode>(tempTimelineMode);
  const [timelineModeQuery, setTimelinModeQuery] =
    useQueryState("timelineMode");
  const [lastDatas, setLastDatas] = useRecoilState(lastDatasAtom);

  useEffect(() => {
    void setTimelinModeQuery(timelineMode);
    setLastDatas([]);
  }, [timelineMode]);

  useEffect(() => {
    if (!searchParam?.get("timelineMode")) {
      setLastDatas([]);
    }
  }, [searchParam?.get("timelineMode")]);

  return { timelineMode, setTimelineMode };
};
