import { ActionFeedLike } from "~/components/Components/ActionFeedLike/ActionFeedLike";
import { ActionShare } from "../ActionShare/ActionShare";
import { ActionFeedComment } from "../ActionFeedComment/ActionFeedComment";

type Props = {
  actionFeedLikeProps: React.ComponentProps<typeof ActionFeedLike>;
  actionFeedCommentProps: React.ComponentProps<typeof ActionFeedComment>;
  shareData: {
    shareUrl: string;
    feedId: number;
  };
};

export const FeedActionBox = (props: Props) => {
  return (
    <div className="flex items-center justify-between ">
      <div className="flex items-center gap-[20px]">
        <ActionFeedLike {...props.actionFeedLikeProps} />
        <ActionFeedComment {...props.actionFeedCommentProps} />
      </div>
      {props.shareData && (
        <ActionShare
          shareUrl={props.shareData.shareUrl}
          feedId={props.shareData.feedId}
        />
      )}
    </div>
  );
};
