import { FeedActionBox } from "~/components/Components/FeedActionBox/FeedActionBox";
import { FeedContent } from "../FeedContent/FeedContent";
import { ProfileAtom } from "~/components/Atoms/ProfileAtom/ProfileAtom";
import { FeedFileThumbnail } from "../FeedFileThumbnail/FeedFileThumbnail";
import Link from "next/link";
import { FilePreview } from "../FilePreview/FilePreview";

type Props = {
  feedProfileProps: React.ComponentProps<typeof ProfileAtom>;
  feedContentProps: React.ComponentProps<typeof FeedContent>;
  feedFileThumbnailProps: React.ComponentProps<typeof FeedFileThumbnail>;
  feedActionBoxProps: React.ComponentProps<typeof FeedActionBox>;
  files?: React.ComponentProps<typeof FilePreview>["namuFiles"];
  isFeedDetail?: boolean;
};

export const FeedCard = (props: Props) => {
  const content = (
    <>
      <div className="px-[20px]">
        <ProfileAtom {...props.feedProfileProps} />
      </div>
      <div className="px-[20px]">
        <FeedContent {...props.feedContentProps} />
      </div>
      {props.isFeedDetail ? (
        props.files && <FilePreview namuFiles={props.files} />
      ) : (
        <FeedFileThumbnail {...props.feedFileThumbnailProps} />
      )}
    </>
  );

  return (
    <div className="flex flex-col gap-[16px] py-[20px]">
      {props.isFeedDetail ? (
        content
      ) : (
        <Link
          href={props.feedContentProps.href}
          className="flex flex-col gap-[16px]"
        >
          {content}
        </Link>
      )}
      <div className={props.isFeedDetail ? "py-[11px]" : ""}>
        <div className="px-[20px]">
          <FeedActionBox {...props.feedActionBoxProps} />
        </div>
      </div>
    </div>
  );
};
