import { useState } from "react";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { TotalVotes } from "../TotalVotes/TotalVotes";

type Props = {
  description: string;
  totalVotes: number;
};

export const VoteFoldDescription = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col items-center gap-[10px] self-stretch px-[20px] py-[16px] text-[15px] leading-[20px]">
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex w-full cursor-pointer items-center justify-between"
      >
        <div className="flex items-center gap-[4px]">
          <p className="text-[15px] leading-[20px]">Detail</p>
          {!isOpen ? (
            <SVGAtom iconName="downArrow" width={13} height={13} color="#000" />
          ) : (
            <SVGAtom iconName="upArrow" width={13} height={13} color="#000" />
          )}
        </div>
        {!isOpen && <TotalVotes totalVotes={props.totalVotes} />}
      </div>
      {isOpen && (
        <p className="w-full whitespace-pre-wrap text-left">
          {props.description}
        </p>
      )}
      {isOpen && (
        <div className="flex w-full items-center justify-end">
          <TotalVotes totalVotes={props.totalVotes} />
        </div>
      )}
    </div>
  );
};
