import { type VoteItem, type Vote, type VoteCategory } from "@prisma/client";
import Link from "next/link";
import { VoteBadgeAtom } from "~/components/Atoms/VoteBadgeAtom/VoteBadgeAtom";
import { formatDate } from "~/utils/formatDate";
import { VoteAction } from "../VoteAction/VoteAction";
import { useRouter } from "next/navigation";
import dayjs from "dayjs";
import { VoteFoldDescription } from "../VoteFoldDescription/VoteFoldDescription";
import Image from "next/image";
import { message } from "antd";
import { VoteCategoryBadgeAtom } from "~/components/Atoms/VoteCategoryBadgeAtom/VoteCategoryBadgeAtom";
import Highlighter from "react-highlight-words";
import { useRecoilState, useRecoilValue } from "recoil";
import { searchTextAtom, voteCommentCountMapAtom } from "~/state/atoms";
import { useSession } from "next-auth/react";

export type VoteDetail = Vote & {
  Category: VoteCategory;
  VoteItems: VoteItem[];
};

type Props = {
  vote: VoteDetail;
  type: "LIST" | "DETAIL";
};

export const VoteCard = (props: Props) => {
  const { data: session } = useSession();
  const router = useRouter();
  const searchText = useRecoilValue(searchTextAtom);
  const [voteCommentCountMap, setVoteCommentCountMap] = useRecoilState(
    voteCommentCountMapAtom,
  );
  const recentCommentCount =
    voteCommentCountMap.get(props.vote.id) ?? props.vote.totalCommentCount;

  const totalVotes =
    props.vote.totalVotedPoint === 0
      ? 0
      : props.vote.totalVotedPoint / props.vote.pointPerVote;

  return props.type === "LIST" ? (
    <div className="flex h-fit w-full flex-col items-start gap-[8px] p-[20px]">
      {props.vote.deletedAt === null &&
      props.vote.isVisible === true &&
      props.vote.startAt < new Date() ? (
        <>
          <Link
            scroll={false}
            href={`/vote/${props.vote.id}`}
            className="flex h-fit w-full flex-col items-start gap-[8px]"
          >
            <div className="flex w-fit items-center gap-[4px]">
              <VoteCategoryBadgeAtom
                category={
                  props.vote.voteCategoryId === 1
                    ? "NORMAL"
                    : props.vote.voteCategoryId === 2
                      ? "FANDOM"
                      : ""
                }
              />
              <VoteBadgeAtom
                status={
                  dayjs(props.vote?.startAt).isBefore(dayjs()) &&
                  dayjs(props.vote?.endAt).isAfter(dayjs())
                    ? "ON"
                    : dayjs(props.vote?.endAt).isBefore(dayjs())
                      ? "END"
                      : "WAITING"
                }
              />
            </div>
            <p className="w-full break-words text-[17px] font-bold leading-[26px]">
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={props.vote.title}
              />
            </p>
            <div className="relative aspect-2 w-full overflow-hidden ">
              <Image
                src={props.vote.imageUrl}
                alt="Vote Image"
                fill
                objectFit="cover"
                className="rounded-[12px]"
              />
            </div>
          </Link>
          <VoteAction
            totalCommentCount={recentCommentCount}
            onVoteClick={() => {
              router.push(`/vote/${props.vote.id}/comment`);
            }}
            startAt={formatDate(props.vote.startAt)}
            endAt={formatDate(props.vote.endAt)}
          />
        </>
      ) : (
        <>
          <div
            onClick={() => {
              if (session?.user.id === props.vote.userId) {
                router.push(`/vote/${props.vote.id}`);
              } else {
                void message.error("Vote not open yet");
              }
            }}
            className="flex h-fit w-full flex-col items-start gap-[8px]"
          >
            <div className="flex w-fit items-center gap-[4px]">
              <VoteCategoryBadgeAtom
                category={
                  props.vote.voteCategoryId === 1
                    ? "NORMAL"
                    : props.vote.voteCategoryId === 2
                      ? "FANDOM"
                      : ""
                }
              />
              <VoteBadgeAtom
                status={
                  dayjs(props.vote?.startAt).isBefore(dayjs()) &&
                  dayjs(props.vote?.endAt).isAfter(dayjs())
                    ? "ON"
                    : dayjs(props.vote?.endAt).isBefore(dayjs())
                      ? "END"
                      : "WAITING"
                }
              />
            </div>
            <p className="w-full break-words text-[17px] font-bold leading-[26px]">
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={props.vote.title}
              />
            </p>
            <div className="relative aspect-2 w-full overflow-hidden ">
              <Image
                src={props.vote.imageUrl}
                alt="Vote Image"
                fill
                objectFit="cover"
                className="rounded-[12px]"
              />
            </div>
          </div>
          <VoteAction
            totalCommentCount={recentCommentCount}
            onVoteClick={() => {
              void message.error("Vote not open yet");
            }}
            startAt={formatDate(props.vote.startAt)}
            endAt={formatDate(props.vote.endAt)}
          />
        </>
      )}
    </div>
  ) : (
    <div className="flex h-fit w-full flex-col items-center">
      <div className="flex w-full flex-col items-start gap-[2px] p-[20px] pb-[12px]">
        {/* <VoteBadgeAtom
          status={
            dayjs(props.vote?.startAt).isBefore(dayjs()) &&
            dayjs(props.vote?.endAt).isAfter(dayjs())
              ? "ON"
              : dayjs(props.vote?.endAt).isBefore(dayjs())
                ? "END"
                : "WAITING"
          }
        /> */}
        <div className="flex w-fit items-center gap-[4px]">
          <VoteCategoryBadgeAtom
            category={
              props.vote.voteCategoryId === 1
                ? "NORMAL"
                : props.vote.voteCategoryId === 2
                  ? "FANDOM"
                  : ""
            }
          />
          <VoteBadgeAtom
            status={
              dayjs(props.vote?.startAt).isBefore(dayjs()) &&
              dayjs(props.vote?.endAt).isAfter(dayjs())
                ? "ON"
                : dayjs(props.vote?.endAt).isBefore(dayjs())
                  ? "END"
                  : "WAITING"
            }
          />
        </div>
        <p className="w-full break-words text-[17px] font-bold leading-[26px]">
          {props.vote.title}
        </p>
        <VoteAction
          startAt={formatDate(props.vote.startAt)}
          endAt={formatDate(props.vote.endAt)}
        />
      </div>
      <div className="relative aspect-2 w-full overflow-hidden">
        <Image
          src={props.vote.imageUrl}
          alt="Vote Image"
          fill
          objectFit="cover"
        />
      </div>
      <div className="w-full bg-[#f5f5f5]">
        {props.vote.endAt < new Date() && (
          <div className="flex w-full flex-col items-start gap-[10px] px-[20px] py-[16px] leading-[20px]">
            <p className="text-[15px]">Result</p>
            <p>
              Congratulations.{" "}
              {
                props.vote.VoteItems.reduce((a, b) =>
                  a.totalVotedPoint > b.totalVotedPoint ? a : b,
                ).title
              }{" "}
              🎉
            </p>
          </div>
        )}
        <div className="w-full border-t-[1px] border-[rgba(0,0,0,0.06)]">
          <VoteFoldDescription
            description={props.vote.description}
            totalVotes={totalVotes}
          />
        </div>
      </div>
    </div>
  );
};
