"use client";

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  isScrolledBottomAtom,
  lastDataTypeAtom,
  lastDatasAtom,
  lastPathnameAtom,
} from "~/state/atoms";
import { api } from "~/trpc/react";
import { TimelineMode } from "~/utils/constants";

export const useInfiniteScroll = ({
  dataType,
  take,
  userId,
  commentFeedId,
  commentParentId,
  commentVoteId,
  feedFandomIds,
  voteFandomId,
  searchText = "",
  timelineMode = TimelineMode.NEW,
  feedOnly,
}: {
  dataType: "FEED" | "VOTE" | "COMMENT" | "POINT" | "TOKEN_HISTORY";
  take: number;
  searchText?: string;
  userId?: string;
  commentFeedId?: number;
  commentParentId?: number;
  commentVoteId?: number;
  voteFandomId?: number;
  feedFandomIds?: number[];
  timelineMode?: TimelineMode;
  feedOnly?: boolean;
}) => {
  const [callApi, setCallApi] = useState(false);
  const [datas, setDatas] = useState<any[]>([]);
  const [lastDataType, setLastDataType] = useRecoilState(lastDataTypeAtom);
  const [lastDatas, setLastDatas] = useRecoilState(lastDatasAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedCount, setFetchedCount] = useState(take);
  const hasMoreData = datas.length >= take && fetchedCount >= take;
  const [lastDataTimestamp, setLastDataTimestamp] = useState(new Date("9999"));
  const [lastTimestamp, setLastTimestamp] = useState(new Date("9999"));
  const [lastDataScore, setLastDataScore] = useState(999999999999999.0);
  const [lastScore, setLastScore] = useState(999999999999999.0);
  const [isScrolledBottom, setIsScrolledBottom] =
    useRecoilState(isScrolledBottomAtom);
  const [lastPathname, setLastPathname] = useRecoilState(lastPathnameAtom);

  const apiGetDatas =
    dataType === "FEED"
      ? api.feed.getMany.useQuery(
          {
            lastTimestamp,
            lastScore,
            take,
            feedFandomIds,
            searchText,
            timelineMode,
            feedOnly,
          },
          { enabled: callApi, cacheTime: 0 },
        )
      : dataType === "VOTE"
        ? api.vote.getMany.useQuery(
            {
              lastTimestamp,
              take,
              fandomId: !voteFandomId ? null : voteFandomId,
              searchText,
            },
            { cacheTime: 0 },
          )
        : dataType === "COMMENT" && commentFeedId
          ? api.comment.getComments.useQuery(
              {
                feedId: commentFeedId,
                lastTimestamp,
                take,
              },
              { cacheTime: 0 },
            )
          : dataType === "COMMENT" && commentParentId
            ? api.comment.getComments.useQuery(
                {
                  parentId: commentParentId,
                  lastTimestamp,
                  take,
                },
                { cacheTime: 0 },
              )
            : dataType === "COMMENT" && commentVoteId
              ? api.comment.getComments.useQuery(
                  {
                    voteId: commentVoteId,
                    lastTimestamp,
                    take,
                  },
                  { cacheTime: 0 },
                )
              : dataType === "POINT" && userId
                ? api.point.getMany.useQuery(
                    {
                      userId,
                      lastTimestamp,
                      take,
                    },
                    { cacheTime: 0 },
                  )
                : dataType === "TOKEN_HISTORY" && userId
                  ? api.token.getTokenHistories.useQuery(
                      {
                        userId,
                        lastTimestamp,
                        take,
                      },
                      { cacheTime: 0 },
                    )
                  : null;

  const setNewDatas = (newDatas: any) => {
    // console.log("DDD", newDatas);
    setIsLoading(false);

    const newData = newDatas?.[0];

    // 이미 불려진 데이터에 포함이 안되어 있을 경우에만 추가
    if (!datas.some((data) => data.id === newData?.id)) {
      setDatas([...datas, ...newDatas]);
      setFetchedCount(newDatas.length);
      setLastDataTimestamp(
        newDatas?.[newDatas.length - 1]?.createdAt ?? new Date(),
      );

      const lastElement = newDatas?.[newDatas.length - 1];

      // lastElement에 score 속성이 있는지 확인하고, 있다면 setLastDataScore 함수를 호출합니다.
      if (lastElement?.score !== undefined) {
        setLastDataScore(lastElement.score);
      }
    }
  };

  useEffect(() => {
    // console.log("AAA", datas);
    if (datas.length > 0 && (dataType === "FEED" || dataType === "VOTE")) {
      setLastDatas(datas);
      setLastDataType(dataType);
    }
  }, [datas, dataType]);

  useEffect(() => {
    // console.log("BBB", callApi, lastDatas, lastDataType);
    if (callApi) {
      return;
    }

    if (
      lastDatas.length === 0 ||
      lastDataType !== dataType ||
      !(lastPathname.startsWith("/feed/") || lastPathname.startsWith("/vote/"))
    ) {
      setCallApi(true);
      return;
    }

    setNewDatas(lastDatas);
  }, [callApi, lastDatas, lastDataType]);

  useEffect(() => {
    // console.log("CCC", callApi, apiGetDatas?.data);
    if (callApi && apiGetDatas?.data) {
      setNewDatas(apiGetDatas?.data);
    }
  }, [callApi, apiGetDatas?.data]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      isScrolledBottom &&
      lastDataTimestamp &&
      lastTimestamp.getTime() !== lastDataTimestamp.getTime()
    ) {
      setLastTimestamp(lastDataTimestamp);
    }

    if (isScrolledBottom && lastDataScore && lastScore !== lastDataScore) {
      setLastScore(lastDataScore);
    }

    if (isScrolledBottom) {
      setCallApi(true);
    }
  }, [isScrolledBottom]);

  const handleScroll = () => {
    const totalHeight = document.documentElement.scrollHeight;
    const scrolledHeight = window.scrollY + window.innerHeight;

    // 모바일 기기에서 스크롤 오차가 100 이내에서 생기는 경우가 있음
    setIsScrolledBottom(
      totalHeight > 300 && scrolledHeight >= totalHeight - 100,
    );
  };

  const resetDatas = () => {
    setDatas([]);
    setIsLoading(true);
    setLastTimestamp(new Date("9999"));
    setLastDataTimestamp(new Date("9999"));
    void apiGetDatas?.refetch();
  };

  return {
    datas,
    hasMoreData,
    isLoading,
    setIsLoading,
    setDatas,
    setLastTimestamp,
    resetDatas,
  };
};
