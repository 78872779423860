/* eslint-disable react/display-name */
import { List } from "antd";
import React from "react";
import { FileType } from "@prisma/client";
import { type NamuFile } from "~/hooks/useFileUpload";
import { CloseCircleFilled } from "@ant-design/icons";
import Image from "next/image";

type Props = {
  namuFiles: NamuFile[];
  onRemove?: (file: NamuFile) => void;
};

export const FilePreview = (props: Props) => {
  return (
    <List
      className="mx-5 bg-white"
      itemLayout="horizontal"
      dataSource={props.namuFiles}
      locale={{
        emptyText: <div className="h-full"></div>,
      }}
      renderItem={(file) => (
        <List.Item className="bg-white" key={file.fileName}>
          <div className="relative w-full">
            {file.fileType?.toUpperCase().includes(FileType.IMAGE) ? (
              <img
                src={file.fileUrl}
                alt={file.fileName}
                className="h-full w-full object-cover"
              />
            ) : file.fileType?.toUpperCase().includes(FileType.VIDEO) ? (
              <video src={file.fileUrl} controls style={{ width: "100%" }} />
            ) : (
              <audio src={file.fileUrl} className="w-full px-[24px]" controls />
            )}
            {props.onRemove ? (
              <CloseCircleFilled
                className="absolute right-[10px] top-[10px] text-[30px]"
                style={{
                  color: "black",
                  background: "white",
                  borderRadius: 100,
                }}
                onClick={() =>
                  props.onRemove
                    ? props.onRemove(file)
                    : () => {
                        return;
                      }
                }
              />
            ) : (
              <></>
            )}
          </div>
        </List.Item>
      )}
    />
  );
};
