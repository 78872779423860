import cn from "classnames";
import Highlighter from "react-highlight-words";
import { useRecoilState } from "recoil";
import { searchTextAtom } from "~/state/atoms";

type Props = {
  text: string;
  className?: string;
  maxLine?: number;
  lineHeight?: number;
  highlight?: boolean;
};

export const LineSplit = (props: Props) => {
  const [searchText, setSearchText] = useRecoilState(searchTextAtom);
  const maxHeight = props.maxLine
    ? `${props.maxLine * (props.lineHeight ?? 20)}px`
    : undefined;

  const lineClampStyle = props.maxLine
    ? {
        maxHeight: maxHeight,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: props.maxLine,
        overflow: "hidden",
      }
    : {};

  return (
    <div style={lineClampStyle as any}>
      {props.text.split("\n").map((line, index) => (
        <span key={index} className={cn("block h-[20px]", props.className)}>
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={line}
          />
        </span>
      ))}
    </div>
  );
};
