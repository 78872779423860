type Props = {
  status: "WAITING" | "ON" | "END";
};

export const VoteBadgeAtom = (props: Props) => {
  return (
    <div className="flex flex-col items-start gap-[2px]">
      <div className="flex w-full items-center gap-[10px]">
        <div
          className={`w-fit whitespace-nowrap rounded-full px-[8px] text-[12px] leading-[20px] text-white ${
            props.status === "ON"
              ? "bg-[#FF4D4F]"
              : props.status === "END"
                ? "bg-[#BFBFBF]"
                : props.status === "WAITING"
                  ? "bg-blue"
                  : ""
          }`}
        >
          {props.status === "ON"
            ? "On"
            : props.status === "END"
              ? "End"
              : props.status === "WAITING"
                ? "coming soon"
                : ""}
        </div>
      </div>
    </div>
  );
};
