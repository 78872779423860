import { BottomSheet } from "../../Components/BottomSheet/BottomSheet";
import { BottomSheetItem } from "../../Components/BottomSheetItem/BottomSheetItem";

type Props = {
  bottomSheetProps: React.ComponentProps<typeof BottomSheet>;
  bottomSheetContent?: React.ComponentProps<
    typeof BottomSheetItem
  >["bottomSheetContent"];
};

export const TimelineModeBottomSheetModule = (props: Props) => {
  return (
    <BottomSheet {...props.bottomSheetProps}>
      {props.bottomSheetContent && (
        <BottomSheetItem
          bottomSheetContent={props.bottomSheetContent}
          closeBottomSheet={props.bottomSheetProps.closeBottomSheet}
        />
      )}
    </BottomSheet>
  );
};
