import { useRouter } from "next/navigation";
import { useRecoilState } from "recoil";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { feedCommentCountMapAtom } from "~/state/atoms";

type Props = {
  feedId: number;
  isFeedDetail?: boolean;
  totalCommentCount: number;
};

export const ActionFeedComment = (props: Props) => {
  const router = useRouter();
  const [feedCommentCountMap, setFeedCommentCountMap] = useRecoilState(
    feedCommentCountMapAtom,
  );

  const recentCommentCount =
    feedCommentCountMap.get(props.feedId) ?? props.totalCommentCount;

  return (
    <div
      className={`flex items-center justify-center gap-[4px] py-[4px] ${
        props.isFeedDetail ? "" : "cursor-pointer"
      }`}
      onClick={() => {
        if (!props.isFeedDetail) {
          router.push(`/feed/${props.feedId}`);
        }
      }}
    >
      <SVGAtom iconName={"message"} color={"black"} width={20} height={20} />
      <p className="cursor-pointer text-[15px] leading-[18px] text-[rgba(0,0,0,0.85)]">
        {recentCommentCount}
      </p>
    </div>
  );
};
