import { useRecoilState } from "recoil";
import { LineSplit } from "../LineSplit/LineSplit";
import { searchTextAtom } from "~/state/atoms";
import Highlighter from "react-highlight-words";
type Props = {
  href: string;
  title: string;
  content: string;
  isNew?: boolean;
  isPopular?: boolean;
};

export const FeedContent = (props: Props) => {
  const [searchText, setSearchText] = useRecoilState(searchTextAtom);

  return (
    <div className="flex flex-col items-start gap-[6px] self-stretch">
      {(props.isNew || props.isPopular) && (
        <div className="flex items-start justify-center gap-[6px]">
          {props.isNew && (
            <div className="flex items-center rounded-[20px] bg-[#e6fffb] px-[8px] py-[4px]">
              <p className="text-[11px] font-[510] leading-[13px] text-[#13c2c2]">
                New
              </p>
            </div>
          )}
          {props.isPopular && (
            <div className="flex items-center rounded-[20px] bg-[#F0F5FF] px-[8px] py-[4px]">
              <p className="text-[11px] font-[510] leading-[13px] text-[#597EF7]">
                Popular
              </p>
            </div>
          )}
        </div>
      )}
      <p className="w-full break-words text-[20px] font-[800] leading-[28px] text-[rgba(0,0,0,0.85)]">
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={[searchText]}
          autoEscape={true}
          textToHighlight={props.title}
        />
      </p>
      <div className="w-full text-[15px] leading-[20px] text-[rgba(0,0,0,0.85)]">
        <LineSplit
          text={props.content}
          className="!h-fit break-words"
          maxLine={props.href.length ? 5 : undefined}
        />
      </div>
    </div>
  );
};
