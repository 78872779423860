import { message } from "antd";

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    void message.success("Text copied to clipboard");
  } catch (err) {
    void message.error("Failed to copy text to clipboard");
  }
};
