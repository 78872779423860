type Props = {
  category: string;
};

export const VoteCategoryBadgeAtom = (props: Props) => {
  return (
    <div className="flex flex-col items-start gap-[2px]">
      <div className="flex w-full items-center gap-[10px]">
        <div
          className={`w-fit whitespace-nowrap rounded-full bg-blue px-[8px] text-[12px] leading-[20px] text-white`}
        >
          {props.category === "FANDOM"
            ? "Fandom Vote"
            : props.category === "NORMAL"
              ? "Vote"
              : ""}
        </div>
      </div>
    </div>
  );
};
