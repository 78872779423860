import { TimelineButton } from "~/components/Components/TimelineModeButton/TimelineModeButton";
import { TimelineModeBottomSheetModule } from "./TimelineModeBottomSheetModule";

type Props = {
  title: string;
  timelineButtonProps: React.ComponentProps<typeof TimelineButton>;
  timelineModeBottomSheetProps: React.ComponentProps<
    typeof TimelineModeBottomSheetModule
  >;
};

export const TimelineModeButtonModule = (props: Props) => {
  return (
    <div className="h-[40px] bg-white">
      <div className="flex h-full w-full items-center pl-[20px]">
        <TimelineButton {...props.timelineButtonProps} />
      </div>

      <TimelineModeBottomSheetModule {...props.timelineModeBottomSheetProps} />
    </div>
  );
};
