import { message } from "antd";
import debounce from "lodash.debounce";
import { useSession } from "next-auth/react";
import { useState, useRef, useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import {
  lastDataTypeAtom,
  lastDatasAtom,
  likedFeedIdsAtom,
} from "~/state/atoms";

type Props = {
  feedId: number;
  likeCount: number;
  updateLike: (feedId: number, isLiked: boolean) => void;
};

export const ActionFeedLike = (props: Props) => {
  const { data: session, update } = useSession();
  const [isLiked, setIsLiked] = useState(false);
  const [likedFeedIds, setLikedFeedIds] = useRecoilState(likedFeedIdsAtom);
  const [likeCount, setLikeCount] = useState(props.likeCount);
  const [lastDataType, setLastDataType] = useRecoilState(lastDataTypeAtom);
  const [lastDatas, setLastDatas] = useRecoilState(lastDatasAtom);

  useEffect(() => {
    setIsLiked(likedFeedIds.includes(props.feedId));
  }, [likedFeedIds]);

  const debouncedUpdateLike = useRef(
    debounce((feedId: number, isLiked: boolean) => {
      props.updateLike(feedId, isLiked);
    }, 500),
  ).current;

  // Ensure the debounced function is not recreated on every render
  useEffect(() => {
    return () => {
      debouncedUpdateLike.cancel();
    };
  }, [debouncedUpdateLike]);

  const handleLikeClick = useCallback(() => {
    if (!session?.user?.id) {
      void message.warning("Login Required");
      return;
    }

    const feedId = props.feedId;
    const newIsLiked = !isLiked;
    setIsLiked(newIsLiked);
    setLikedFeedIds((prevLikedFeedIds) =>
      newIsLiked
        ? [...prevLikedFeedIds, feedId]
        : prevLikedFeedIds.filter((id) => id !== feedId),
    );
    setLikeCount((prevLikeCount) => prevLikeCount + (newIsLiked ? 1 : -1));
    if (lastDataType === "FEED" && lastDatas.length) {
      const updatedLastDatas = lastDatas.map((feed) => {
        if (feed.id === feedId) {
          return { ...feed, totalLikeCount: likeCount + (newIsLiked ? 1 : -1) };
        }
        return feed;
      });

      // 변경된 배열로 상태를 업데이트합니다.
      setLastDatas(updatedLastDatas);
    }

    // Call the debounced updateLike function
    debouncedUpdateLike(feedId, newIsLiked);
  }, [session?.user, isLiked, props.feedId, debouncedUpdateLike]);

  return (
    <div
      className="flex cursor-pointer items-center justify-center gap-[4px] py-[4px]"
      onClick={handleLikeClick}
    >
      <SVGAtom
        iconName={isLiked ? "reactionOn" : "heart"}
        color={isLiked ? "#004BFF" : "black"}
        width={20}
        height={20}
      />
      <p className="text-[15px] leading-[18px] text-[rgba(0,0,0,0.85)]">
        {likeCount}
      </p>
    </div>
  );
};
