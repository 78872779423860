import "./custom.css";
import { useState } from "react";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { ShareModal } from "../ShareModal/ShareModal";

type Props = {
  shareUrl: string;
  feedId?: number;
  voteId?: number;
};

export const ActionShare = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="!cursor-pointer">
      <SVGAtom
        iconName={"upload"}
        color={"black"}
        width={20}
        height={20}
        onClick={showModal}
      />
      <ShareModal
        shareUrl={props.shareUrl}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        feedId={props.feedId}
        voteId={props.voteId}
      />
    </div>
  );
};
