import Image from "next/image";
import Highlighter from "react-highlight-words";
import { useRecoilState } from "recoil";
import { searchTextAtom } from "~/state/atoms";

type Props = {
  image: string;
  userName: string;
  fandomName?: string;
  feedDate: string;
};

export const ProfileAtom = (props: Props) => {
  const [searchText, setSearchText] = useRecoilState(searchTextAtom);

  return (
    <div className="flex w-full items-center gap-[8px]">
      <div className="flex w-full items-center gap-[10px]">
        <div className="flex h-[32px] w-[32px] items-center justify-center overflow-hidden rounded-full bg-[#9c9a9a]">
          <Image
            src={props.image}
            width={32}
            height={32}
            alt={"User Profile Image"}
            objectFit="contain"
          />
        </div>
        <div
          className="flex flex-col items-start justify-center gap-[2px]"
          style={{ maxWidth: "calc(100% - 42px)" }}
        >
          <p className="text-[15px] font-medium leading-[18px] text-[rgba(0,0,0,0.85)]">
            {props.userName}
          </p>
          <div className="flex w-full items-start justify-center gap-[4px] text-[13px] font-light leading-[16px] text-[rgba(0,0,0,0.45)]">
            {props.fandomName && (
              <>
                <p className="flex-grow truncate">
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={props.fandomName}
                  />
                </p>
                <p>·</p>
              </>
            )}
            <p className="w-fit whitespace-nowrap">{props.feedDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
