import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";

type Props = {
  totalCommentCount?: number;
  onVoteClick?: () => void;
  startAt: string;
  endAt: string;
};

export const VoteAction = (props: Props) => {
  return (
    <div className="flex max-w-full items-center gap-[12px]">
      {props.totalCommentCount !== null && props.onVoteClick ? (
        <div
          className="flex h-[28px] cursor-pointer items-center gap-[4px] py-[4px]"
          onClick={props.onVoteClick}
        >
          <SVGAtom iconName="message" width={16} height={16} color="#000" />
          {props.totalCommentCount}
        </div>
      ) : undefined}
      <div className="flex h-[28px] items-center gap-[4px] py-[4px]">
        <SVGAtom iconName="time" width={16} height={16} color="#000" />
        {props.startAt} ~ {props.endAt}
      </div>
    </div>
  );
};
